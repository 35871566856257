import React, { useState } from 'react';
import { styled, theme } from '../../../Theme';
import UserMenuMobile from './UserMenuMobile';
import { ArrowRightIcon, CloseIcon } from '../../Icons';
import { StyleOrStyleArray } from '@glitz/type';

type PropTypes = {
  linkText: string;
  isTepeConnect?: boolean;
  toggleMenu: () => void;
  toggleModalState: () => void;
};

function ProfileMenuItem({
  linkText,
  toggleMenu,
  toggleModalState,
  isTepeConnect = false,
}: PropTypes) {
  const [isSubMenuOpen, setSubMenuOpen] = useState<boolean>(false);

  const toggleSubMenu = () => {
    setSubMenuOpen(!isSubMenuOpen);
  };

  return (
    <Link onClick={toggleSubMenu}>
      <TextWrapper>
        {linkText}
        <SubMenuButton>
          <CloseIcon css={isSubMenuOpen ? closeIconCss : { display: 'none' }} />
          <ArrowRightIcon
            css={!isSubMenuOpen ? arrowIconCss : { display: 'none' }}
          />
        </SubMenuButton>
      </TextWrapper>

      <Wrapper css={isSubMenuOpen ? SubMenuOpenStyle : {}}>
        <Divider />
        <UserMenuMobile
          onMenuClose={toggleMenu}
          toggleModalState={toggleModalState}
          isTepeConnect={isTepeConnect}
        />
      </Wrapper>
    </Link>
  );
}

const Link = styled.div({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  textDecoration: 'none',
  font: { size: theme.epsilon },
  color: theme.breadText,
});

const SubMenuOpenStyle: StyleOrStyleArray = {
  maxHeight: theme.spacing(200),
  transition: {
    timingFunction: theme.animation.timingFn,
    property: 'all',
    duration: theme.timings.one,
  },
};

const Wrapper = styled.div({
  position: 'relative',
  display: 'block',
  overflow: 'hidden',
  maxHeight: 0,
  transition: {
    timingFunction: theme.animation.timingFn,
    property: 'all',
    duration: theme.timings.threeTenths,
  },
});

const TextWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  padding: { x: theme.spacing(4), y: theme.spacing(5) },
});

const closeIconCss: StyleOrStyleArray = {
  width: theme.spacing(4),
  height: theme.spacing(4),
  position: 'relative',
  left: theme.spacing(1),
};

const Divider = styled.div({
  position: 'relative',
  bottom: `-${theme.spacing(4)}`,
  marginLeft: theme.spacing(4),
  height: theme.tiny,
  width: theme.spacing(16),
  backgroundColor: theme.blueDark,
});

const arrowIconCss: StyleOrStyleArray = {
  width: theme.spacing(2),
  position: 'relative',
  height: theme.spacing(4),
  transition: {
    duration: theme.timings.oneFifth,
    property: 'all',
    timingFunction: theme.animation.timingFn,
  },
};

const SubMenuButton = styled.div({
  backgroundColor: theme.white,
  position: 'relative',
  border: {
    xy: {
      width: theme.none,
    },
  },
  padding: { y: theme.spacing(1), x: theme.spacing(2) },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ':active': {
    outline: { width: 0 },
  },
  ':focus': {
    outline: { width: 0 },
  },
});

export default ProfileMenuItem;
