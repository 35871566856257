import React from 'react';
import { StyleOrStyleArray } from '@glitz/type';
import { styled, theme } from '../../../Theme';
import { pseudo } from '@glitz/core';
import IHeaderLink from '../../Models/Headers/HeaderLink.interface';
import { useHeaderData } from '../../Models/Headers/Header';
import { useAppSettingsData } from '../../AppSettingsProvider/AppSettingsProvider';
import { useUserStateData } from '../../UserContextProvider/UserContextProvider';
import { useKexNavigate } from '../../KexRouter/KexRouter';
import Translate from '../../Common/Translate';
import KexLink from '../../KexLink/KexLink';
import { SmallButton } from '../../Button/Button';
import { SignOut } from '../../../AccountPage/Account';

export type PropType = {
  onMenuClose: () => void;
  toggleModalState: (id: number) => void;
  isTepeConnect: boolean;
};

function UserMenuMobile({
  onMenuClose,
  toggleModalState,
  isTepeConnect,
}: PropType) {
  const {
    translations: {
      'account/signOut': signOut,
      'account/admin/greeting': greeting,
      'account/admin/description': description,
      'account/admin/manageImpersonation': manageImpersonation,
      'account/signIn': signIn,
      'account/admin/yourAccount': yourAccount,
      'tepeConnect/titleValues/signUp': signUpLabel,
      'tepeConnect/titleValues/myPage': myPageLabel,
    },
    staticPages: {
      accountPage,
      impersonationPage,
      tepeConnectRegistrationPage,
      tepeConnectProfilePage,
    },
    languageRoute,
    commerce,
  } = useAppSettingsData();

  const kexNavigate = useKexNavigate();
  const { profileLinks } = useHeaderData();
  const {
    fullName,
    isImpersonating,
    allowedToImpersonate,
    authenticated,
  } = useUserStateData();

  const signOutAction = () => {
    SignOut(accountPage, languageRoute);
    onMenuClose();
  };

  const loginAction = () => {
    onMenuClose();
    toggleModalState(1);
  };

  const loginActionTepeConnect = () => {
    onMenuClose();
    toggleModalState(2);
  };

  const goToAccountAction = () => {
    onMenuClose();
    kexNavigate(accountPage);
  };

  const signUpAction = () => {
    onMenuClose();
    kexNavigate(tepeConnectRegistrationPage);
  };

  const goToConnectProfile = () => {
    onMenuClose();
    kexNavigate(tepeConnectProfilePage);
  };

  const renderSalesRepTop = () => (
    <>
      <Translate translation={greeting} values={[fullName]} />
      {(isImpersonating || description) && (
        <Description>{description}</Description>
      )}
    </>
  );

  const renderPartnerTop = () => (
    <>
      {authenticated ? (
        <TopButton onClick={goToAccountAction} isActive>
          {yourAccount}
        </TopButton>
      ) : (
        <TopButton onClick={loginAction} isActive>
          {signIn}
        </TopButton>
      )}
    </>
  );

  const renderSalesRepBottom = () => (
    <>
      <StyledKexLink href={impersonationPage} onClick={onMenuClose}>
        {manageImpersonation}
      </StyledKexLink>
    </>
  );

  return (
    <UserMenuContainer>
      {!isTepeConnect ? (
        <>
          <TopSection>
            {allowedToImpersonate ? renderSalesRepTop() : renderPartnerTop()}
          </TopSection>
          {profileLinks &&
            profileLinks.map((item: IHeaderLink) => (
              <styled.Div key={item.id}>
                {(item.isCommerceContent ? commerce : true) && (
                  <StyledKexLink
                    href={item.href}
                    onClick={onMenuClose}
                    css={!allowedToImpersonate ? partnerMenuLinkStyle : {}}
                  >
                    {item.text}
                  </StyledKexLink>
                )}
              </styled.Div>
            ))}
          {(allowedToImpersonate || isImpersonating) && renderSalesRepBottom()}
          <BottomWrapper>
            <StyledKexLink onClick={signOutAction}>{signOut}</StyledKexLink>
          </BottomWrapper>
        </>
      ) : (
        <>
          {authenticated ? (
            <>
              <ConnectButtonWrapper>
                <TopButton onClick={goToConnectProfile} isActive>
                  {myPageLabel}
                </TopButton>
              </ConnectButtonWrapper>
              <ConnectButtonWrapper>
                <TopButton onClick={signOutAction}>{signOut}</TopButton>
              </ConnectButtonWrapper>
            </>
          ) : (
            <>
              <ConnectButtonWrapper>
                <TopButton onClick={signUpAction} isActive>
                  {signUpLabel}
                </TopButton>
              </ConnectButtonWrapper>
              <ConnectButtonWrapper>
                <TopButton onClick={loginActionTepeConnect}>{signIn}</TopButton>
              </ConnectButtonWrapper>
            </>
          )}
        </>
      )}
    </UserMenuContainer>
  );
}

export default UserMenuMobile;

const UserMenuContainer = styled.div({
  height: '100%',
  overflow: 'hidden',
  maxWidth: theme.userMenuMaxWidth,
  width: '100%',
  backgroundColor: theme.white,
});

const TopSection = styled.div({
  color: theme.black,
  padding: {
    xy: theme.spacing(4),
  },
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
});

const ConnectButtonWrapper = styled.div({
  color: theme.black,
  padding: {
    xy: theme.spacing(4),
  },
  ...pseudo(':last-child', {
    paddingTop: theme.none,
  }),
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
});

const TopButton = styled(SmallButton, {
  width: '100%',
  height: theme.spacing(11.5),
  padding: { xy: theme.none },
  fontFamily: theme.fontFamily.heading,
});

const Description = styled.div({
  marginTop: theme.spacing(1),
  fontWeight: theme.fontWeight.lighter,
});

const StyledKexLink = styled(KexLink, {
  display: 'flex',
  width: '100%',
  padding: { x: theme.spacing(4), y: theme.spacing(4) },
  textDecoration: 'none',
  font: {
    size: theme.beta,
    weight: theme.fontWeight.normal,
  },
  color: theme.secondaryText,
  ':hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

const partnerMenuLinkStyle: StyleOrStyleArray = {
  ...pseudo(':not(:last-of-type)', {
    border: { bottom: { color: theme.lightGray, width: theme.tiny } },
  }),
};

const BottomWrapper = styled.div({
  border: { top: { color: theme.lightGray, width: theme.tiny } },
});
