import React from 'react';
import { styled, theme } from '../../Theme';
import usePreventBackgroundScroll from '../Hooks/usePreventBackgroundScroll';
import { StyleOrStyleArray } from '@glitz/type';

type PropTypes = {
  clickEvnt?: () => void;
  enableScroll?: boolean;
  onlyContent?: boolean;
};

function Overlay({ clickEvnt, enableScroll, onlyContent }: PropTypes) {
  usePreventBackgroundScroll(enableScroll);

  return (
    <OverlayDiv onClick={clickEvnt} css={onlyContent ? onlyContentStyle : {}} />
  );
}

export default Overlay;
const onlyContentStyle: StyleOrStyleArray = {
  zIndex: theme.zIndex.contentOverlayWithHeader,
};

const OverlayDiv = styled.div({
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  zIndex: theme.zIndex.MobileOverlay,
  left: 0,
  top: 0,
  opacity: '0.50',
  backgroundColor: theme.backdrop,
});
