import React, {
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
  useCallback,
} from 'react';
import { styled, theme } from '../../../Theme';
import { ArrowRightIcon, CloseIcon } from '../../Icons';
import IHeaderLink from '../../Models/Headers/HeaderLink.interface';
import { StyleOrStyleArray } from '@glitz/type';
import KexLink from '../../KexLink/KexLink';
import { useUserStateData } from '../../UserContextProvider/UserContextProvider';
import { SignOut } from '../../../AccountPage/Account';
import { useAppSettingsData } from '../../AppSettingsProvider/AppSettingsProvider';
import useMedia from '../../Hooks/useMedia';
import ContentDivider from '../../Divider/Divider';
import useCurrentPage from '../../../Shared/Hooks/useCurrentPage';
import PageModelBase from '../../Models/PageModelBase.interface';

type PropTypes = {
  item: IHeaderLink;
  toggleMenu?: () => void;
  activeId: null | number;
  setActive: (id: number) => void;
  depth?: number;
  isProfileLinks?: boolean;
  expandedByDefault?: boolean;
};

function MenuItem({
  item,
  toggleMenu,
  activeId,
  setActive,
  depth = 0,
  isProfileLinks,
  expandedByDefault = false,
}: PropTypes) {
  const { authenticated } = useUserStateData();
  const {
    translations: { 'account/signOut': signOut },
    staticPages: { accountPage },
    languageRoute,
    commerce,
  } = useAppSettingsData();
  const { pageType, pageId } = useCurrentPage<PageModelBase>();

  const isMobile = useMedia(theme.mediaQuery.mediaMaxLarge);
  const [isSubMenuOpen, setSubMenuOpen] = useState<boolean>(expandedByDefault);
  const [subLinkContainerHeight, setSubLinkContainerHeight] = useState<
    0 | 'auto'
  >(0);
  const subLinkContainerRef = useRef<HTMLDivElement>(null);
  const hasSubLinks = item.subLinks && !!item.subLinks.length;

  const findActiveNode = useCallback(
    (subs: IHeaderLink[]) => {
      hasSubLinks &&
        subs.map((item: IHeaderLink) =>
          pageId === item.id
            ? setSubMenuOpen(true)
            : item.subLinks?.length > 0 && findActiveNode(item.subLinks)
        );
    },
    [hasSubLinks, pageId]
  );

  useEffect(() => {
    if (pageType === 'StartPage') {
      !expandedByDefault && setSubMenuOpen(false);
      setActive(-1);
    }

    if (!expandedByDefault) {
      pageId === item.id
        ? setActive(item.id)
        : hasSubLinks && findActiveNode(item.subLinks);
    }
  }, [
    pageId,
    item,
    expandedByDefault,
    findActiveNode,
    hasSubLinks,
    pageType,
    setActive,
  ]);

  useLayoutEffect(() => {
    const subLinkContainerElm = subLinkContainerRef.current;

    if (isSubMenuOpen) {
      if (subLinkContainerElm) {
        const onTransitionend = () => {
          subLinkContainerElm.style.cssText = ``;
          subLinkContainerElm.removeEventListener(
            'transitionend',
            onTransitionend
          );
          setSubLinkContainerHeight('auto');
        };
        subLinkContainerElm.style.cssText =
          'opacity: 0; position:absolute; height: auto;';
        const containerHeight = subLinkContainerElm.offsetHeight;

        subLinkContainerElm.style.cssText = '';

        subLinkContainerElm.addEventListener('transitionend', onTransitionend);
        setTimeout(() => {
          if (subLinkContainerElm) {
            subLinkContainerElm.style.cssText = `height: ${containerHeight}px;`;
          }
        }, 0);
      }
    } else {
      if (subLinkContainerElm) {
        const onTransitionend = () => {
          subLinkContainerElm.style.cssText = ``;
          subLinkContainerElm.removeEventListener(
            'transitionend',
            onTransitionend
          );
          setSubLinkContainerHeight(0);
        };
        subLinkContainerElm.addEventListener('transitionend', onTransitionend);
        const containerHeight = subLinkContainerElm.offsetHeight;
        subLinkContainerElm.style.cssText = `height: ${containerHeight}px;`;
        setTimeout(() => {
          if (subLinkContainerElm) {
            subLinkContainerElm.style.cssText = `height: 0;`;
          }
        }, 0);
      }
    }
  }, [subLinkContainerHeight, isSubMenuOpen]);

  const subLinkContainerFirstDepthStyle: StyleOrStyleArray = {
    border: {
      left: {
        style: 'solid',
        width: theme.tiny,
        color: theme.primary,
      },
    },
  };

  const subLinkContainerStyle: StyleOrStyleArray = [
    {
      height: subLinkContainerHeight,
    },
    depth === 1 ? subLinkContainerFirstDepthStyle : {},
    depth > 0
      ? {
          margin: {
            left: theme.spacing(4),
          },
        }
      : {},
  ];

  const navLinkStyle: StyleOrStyleArray = [
    { flexBasis: hasSubLinks ? '85%' : '100%' },
    depth === 0
      ? { fontWeight: theme.fontWeight.lighter }
      : { fontWeight: theme.fontWeight.normal },
    activeId === item.id
      ? {
          fontWeight: theme.fontWeight.bold,
        }
      : {},
    {
      fontSize: depth === 0 ? theme.epsilon : theme.spacing(3.5),
    },
    !isSubMenuOpen
      ? {
          ':hover': {
            textDecoration: 'underline',
          },
        }
      : {},
  ];

  const closeIconCss: StyleOrStyleArray = {
    width: theme.spacing(4),
    height: theme.spacing(4),
    position: 'relative',
    left: theme.spacing(1),
  };

  return (
    <Item
      css={item.isCommerceContent ? (commerce ? {} : { display: 'none' }) : {}}
    >
      <NavLinkContainer>
        <NavLink
          href={item.href}
          onNavigated={() => {
            setActive(item.id);
            toggleMenu && toggleMenu();
          }}
          css={navLinkStyle}
        >
          {item.text}
          {isSubMenuOpen && depth === 0 && (
            <DividerWrapper>
              <ContentDivider />
            </DividerWrapper>
          )}
        </NavLink>
        {((!expandedByDefault && hasSubLinks) ||
          (!expandedByDefault && authenticated && isMobile && hasSubLinks)) && (
          <SubMenuButton onClick={() => setSubMenuOpen(!isSubMenuOpen)}>
            <styled.Div
              css={isSubMenuOpen ? { display: 'block' } : { display: 'none' }} // Ugly but needed because of useOutsideClick behavior which would otherwise click through
            >
              <CloseIcon css={closeIconCss} />
            </styled.Div>
            <styled.Div
              css={isSubMenuOpen ? { display: 'none' } : { display: 'block' }} // Ugly but needed because of useOutsideClick behavior which would otherwise click through
            >
              <StyledArrowRightIcon />
            </styled.Div>
          </SubMenuButton>
        )}
      </NavLinkContainer>
      {hasSubLinks && (
        <SubLinkContainer ref={subLinkContainerRef} css={subLinkContainerStyle}>
          {item.subLinks.map((item: IHeaderLink) => (
            <MenuItem
              key={item.linkId}
              item={item}
              toggleMenu={toggleMenu}
              activeId={activeId}
              setActive={setActive}
              depth={depth + 1}
            />
          ))}
        </SubLinkContainer>
      )}
      {authenticated && isMobile && isProfileLinks && (
        <SubLinkContainer
          ref={subLinkContainerRef}
          css={{ height: subLinkContainerHeight }}
        >
          <StyledKexLink onClick={() => SignOut(accountPage, languageRoute)}>
            {signOut}
          </StyledKexLink>
        </SubLinkContainer>
      )}
    </Item>
  );
}

const DividerWrapper = styled.div({
  position: 'absolute',
  bottom: `-${theme.spacing(4)}`,
});

const NavLinkContainer = styled.div({
  display: 'flex',
});

const StyledArrowRightIcon = styled(ArrowRightIcon, {
  width: theme.spacing(2),
  height: theme.spacing(4),
  transition: {
    duration: theme.timings.oneFifth,
    property: 'all',
  },
});

const StyledKexLink = styled(KexLink, {
  display: 'flex',
  width: '100%',
  padding: { x: theme.spacing(4), y: theme.spacing(5) },
  textDecoration: 'none',
  font: { size: theme.beta },
  color: theme.breadText,
  ':hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

const SubLinkContainer = styled.div({
  display: 'block',
  overflowY: 'hidden',
  transition: {
    duration: theme.timings.threeTenths,
    property: 'height',
    timingFunction: theme.animation.timingFn,
  },
});

const Item = styled.div({
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  flexDirection: 'column',
});

const NavLink = styled(KexLink, {
  position: 'relative',
  textDecoration: 'none',
  color: theme.breadText,
  padding: { x: theme.spacing(4), y: theme.spacing(5) },
});

const SubMenuButton = styled.button({
  backgroundColor: theme.white,
  border: {
    xy: {
      width: 0,
    },
  },
  padding: { y: theme.spacing(3), x: theme.spacing(6) },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ':active': {
    outline: { width: 0 },
  },
  ':focus': {
    outline: { width: 0 },
  },
});

export default MenuItem;
