import React from 'react';
import { StyledComponentWithRef } from '@glitz/react';
import { styled } from '../../Theme';

type PropTypes = {
  translation: string;
  values: any[];
  component?: StyledComponentWithRef<React.HTMLAttributes<any>, any>;
};

const formatString = (str: string, values: any[]) => {
  const templateSplit = new RegExp(/{(\d)}/g);
  const isNumber = new RegExp(/^\d+$/);
  const splitedText = str.split(templateSplit);
  return splitedText.map(sentence => {
    if (isNumber.test(sentence)) {
      const value = values[Number(sentence)];
      return value;
    }
    return sentence;
  });
};

/*
 * translations function, used for simple translations outside jsx render - returns basic translated string
 * example: translation(yourTranslationString, ['values', 'to', 'replace'])
 */
export const translate = (translation: string, values: any[]) => {
  return formatString(translation, values);
};

/*
 * Translations component, used for components etc. Returns component.
 * example: <Translation translation={yourTranslationString} values={['values', 'to', 'replace']} />
 * It's possible to pass react elements aswell
 * example: <Translation translation={yourTranslationString} values={[<styled.P>My cool component</styled.P>]} />
 */
function Translate({ translation, values, component = styled.P }: PropTypes) {
  const Component = component;
  const translationArray = translate(translation, values);
  return translationArray && translationArray.length > 0 ? (
    <Component>
      {translationArray.map((t, i) => (
        <React.Fragment key={i}>{t}</React.Fragment>
      ))}
    </Component>
  ) : (
    <React.Fragment />
  );
}

export const mapObjectStringTranslations = (
  translations: { [key: string]: string },
  object: { [key: string]: string }
) => {
  if (!translations || !object) return [];
  const arr = [];
  for (const [...values] of Object.entries(object)) {
    const translation = `${values[1]
      .slice(0, 1)
      .toLocaleLowerCase()}${values[1].substring(1)}`;
    arr.push(translations[translation] ?? values[1]);
  }
  return arr;
};

export const mapObjectStringTranslation = (
  translations: { [key: string]: string },
  object: string
) => {
  if (!translations || !object) return '';
  const translation = `${object
    .slice(0, 1)
    .toLocaleLowerCase()}${object.substring(1)}`;

  return translations[translation];
};

export default Translate;
