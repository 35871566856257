import React, { useState, useRef } from 'react';
import { styled, theme } from '../../Theme';
import MenuItem from './MenuItem/MenuItem';
import IHeaderLink from '../Models/Headers/HeaderLink.interface';
import Overlay from '../Common/Overlay';
import { useHeaderData } from '../Models/Headers/Header';
import useOutsideClick from '../Hooks/useOutsideClick';
import { pseudo, media } from '@glitz/core';
import { useAppSettingsData } from '../AppSettingsProvider/AppSettingsProvider';
import { useKexNavigate } from '../../Shared/KexRouter/KexRouter';
import KexLink from '../KexLink/KexLink';
import ProfileMenuItem from './MenuItem/ProfileMenuItem';

type PropTypes = {
  isOpen: boolean;
  onMenuClose: () => void;
  menuId?: number | null;
  toggleModalState: () => void;
  setUserMenuId: (value: number) => void;
};

function Menu({ isOpen, onMenuClose, toggleModalState }: PropTypes) {
  const { navigationLinks, topMenuLinks } = useHeaderData();
  const kexNavigate = useKexNavigate();

  const {
    translations: {
      'common/findReseller': findResellerLabel,
      'common/partner': partnerLabel,
      'languageSelectorPage/findYourRegion': findYourRegionLabel,
      'tepeConnect/titleValues/tepeConnect': tepeConnectLabel,
    },
    staticPages: { languageSelectorPage, retailerListingPage },
    commerce,
    tepeConnectIsActive,
  } = useAppSettingsData();

  const [activeId, setActiveId] = useState<null | number>(null);

  const menuRef = useRef<HTMLDivElement>(null);

  useOutsideClick(menuRef, () => {
    isOpen && onMenuClose();
  });

  const goTolanguageSelectorAction = () => {
    onMenuClose();
    kexNavigate(languageSelectorPage);
  };

  const goToRetailerAction = () => {
    onMenuClose();
    kexNavigate(retailerListingPage);
  };

  return (
    <>
      <Wrapper
        ref={menuRef}
        css={{ transform: isOpen ? 'translateX(0)' : 'translateX(-100%)' }}
      >
        <List>
          {navigationLinks &&
            navigationLinks.map((item: IHeaderLink, index: number) => (
              <styled.Div key={item.id + index}>
                {(item.isCommerceContent ? commerce : true) && (
                  <>
                    <MenuItem
                      key={item.id}
                      item={item}
                      toggleMenu={onMenuClose}
                      activeId={activeId}
                      setActive={id => setActiveId(id)}
                    />
                    <Divider />
                  </>
                )}
              </styled.Div>
            ))}
          <StyledKexLink onClick={goToRetailerAction}>
            {findResellerLabel}
          </StyledKexLink>
          <Divider />

          <ProfileMenuItem
            linkText={partnerLabel}
            toggleMenu={onMenuClose}
            toggleModalState={toggleModalState}
          />

          <Divider />
          <BottomWrapper>
            {topMenuLinks &&
              topMenuLinks.map((link: IHeaderLink, index: number) => (
                <SmallKexLink
                  key={link.id + index}
                  href={link.href}
                  onClick={() => {
                    onMenuClose();
                  }}
                >
                  {link.text}
                </SmallKexLink>
              ))}
            <SmallKexLink onClick={goTolanguageSelectorAction}>
              {findYourRegionLabel}
            </SmallKexLink>
          </BottomWrapper>
        </List>
      </Wrapper>
      {isOpen && <Overlay />}
    </>
  );
}

export default Menu;

const Wrapper = styled.div({
  position: 'fixed',
  left: theme.none,
  backgroundColor: theme.white,
  height: '100%',
  width: '320px',
  transition: {
    duration: theme.timings.threeTenths,
    property: 'all',
  },
  zIndex: theme.zIndex.Flyout,
});

const BottomWrapper = styled.div({
  marginTop: theme.spacing(4),
});

const StyledKexLink = styled(KexLink, {
  display: 'flex',
  width: '100%',
  padding: { x: theme.spacing(4), y: theme.spacing(5) },
  textDecoration: 'none',
  font: { size: theme.epsilon },
  color: theme.breadText,
  ':hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

const SmallKexLink = styled(KexLink, {
  display: 'flex',
  width: '100%',

  padding: { x: theme.spacing(4), y: theme.spacing(2) },
  textDecoration: 'none',
  color: theme.breadText,
  ':hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  fontSize: theme.beta,
});

const List = styled.ul({
  listStyle: 'none',
  padding: {
    xy: theme.none,
  },
  margin: {
    xy: theme.none,
  },
  overflowY: 'scroll',
  maxHeight: '100vh',
  ...pseudo('::-webkit-scrollbar', {
    WebkitAppearance: 'none',
    display: 'none',
  }),
  scrollbarWidth: 'none',
  ...media(theme.mediaQuery.mediaMinSmall, {
    maxHeight: 'calc(100vh - 100px)',
  }),
});

const Divider = styled.div({
  backgroundColor: theme.lightAqua,
  height: theme.tiny,
  margin: { x: theme.spacing(2) },
});
